.contactMeForm{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: stretch;
}

.contactMeForm h2,
.contactMeForm input,
.contactMeForm textarea{
    width: 100%;
    resize: none;
}

.contactMeForm span{
    margin-left: auto;
    align-self: flex-end;
    color: red;
    font-weight: 600;
    font-size: var(--text-sm)
}

.contactMeForm input,
.contactMeForm textarea{
    border-radius: 7.5px;
    border-style: solid;
    padding:0.25rem 0.5rem;
    font-family: var(--font-family-body);
    font-size: var(--text-sm);
}

.contactMeForm input[type="submit"]{
    align-self: flex-end;
    box-shadow: var(--elevation-1);
}

.contactMeForm div{
    display: flex;
    flex-flow: row wrap;
}

.contactMeForm h2{
    margin-bottom: 0;
}

.contactMeForm textarea{
    padding:0.5rem;
    overflow-y: auto;
    flex: 2 0 auto;
}

.contactMeForm label{
    padding-top: 1rem;
    line-height: var(--base-size);
}

/*Send Button*/
.contactMeForm input[type=submit]{
    max-width: 200px;
    margin-left: auto;
    border: none;
    background-color: var(--color-primary-300);
    color: var(--color-neutral-100);
    margin: 1rem 0 1rem auto;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.contactMeForm input[type=submit]:hover{
    color: white;
    font-weight: 600;
}

@supports(backdrop-filter: blur(3px)){
    .contactMeForm{
        backdrop-filter: blur(3px);
    }
}

.invalid {
    background-color: #fddde6;
    border: 2px solid red;
}