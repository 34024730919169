.projectCard{
    box-shadow: var(--elevation-1);
    border-radius: var(--border-radius-card);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
}

.projectCard:not(:first-child):not(:nth-child(2)){
    margin-top: 2%;
}

.projectCard:nth-child(even){
    margin-right: 1%;
}

.techStack{
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    padding: 0.5rem;
    margin-bottom: 0;
    min-height: 80px;
    align-items: center;
}

.techStack li{
    margin: 0;
    padding: 0;
    display: flex;
}

.techStack li img{
    margin: 0;
    padding: 0;
    height: 48px;
    margin-right: 1rem;
}

.projectCard a{
    background-color: var(--color-primary-200);
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-card);
    color: var(--color-neutral-500);
    text-decoration: none;
    box-shadow: var(--elevation-1);
    margin-left: auto;
    margin-right: 0.5rem;

}

.projectCard a:first-of-type{
    margin-bottom: auto;
    margin-top: 0.5rem;
}

.projectCard a:last-of-type{
    margin-top: auto;
}

.projectCard a:hover
{
    background-color: var(--color-primary-100);
}

.techStack{
    background-color: rgba(238, 240, 247, 0.95);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--border-radius-card);
    border-bottom-right-radius: var(--border-radius-card);
}

.projectCardHeader{
    display: flex;
    background-color: rgba(238, 240, 247, 0.95);
    margin-top: 0;
    padding: 0 1rem;
    color: var(--color-neutral-500);
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: var(--border-radius-card);
    border-top-left-radius: var(--border-radius-card);
}

@supports(backdrop-filter: blur(3px)){
    .projectCardHeader{
        backdrop-filter: blur(3px);
        background-color: rgba(238, 240, 247, 0.75);
    }

    .techStack{
        backdrop-filter: blur(3px);
        background-color: rgba(238, 240, 247, 0.75);
    }
}

