:root{
    /*Colors*/
    --color-primary-100: #CCD3E6;
    --color-primary-200: #95A1BF;
    --color-primary-300: #2C4B9C;
    --color-primary-400: #45598C;
    --color-primary-500: #374873;

    --color-neutral-100: #EEF0F7;
    --color-neutral-200: #BCC1CD;
    --color-neutral-300: #545D74;
    --color-neutral-400: #2C3449;
    --color-neutral-500: #1A1B1E;

    --font-family-header: 'Open Sans', sans-serif;
    --font-family-body: 'Yantramanav', sans-serif;



    /*Shadows*/
    --elevation-0: none;
    --elevation-1: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
    --elevation-2: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);
    --elevation-3: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);
    --elevation-4: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    --elevation-5: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20);
    --elevation-6: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
    --elevation-7: 0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20);
    --elevation-8: 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);
    --elevation-9: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20);
    --elevation-10: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20);

    --border-radius-card: 7.5px;

    --base-size: 1em;
    --type-scale: 1.125;
    --h5: calc(var(--base-size) * var(--type-scale));
    --h4: calc(var(--h5) * var(--type-scale));
    --h3: calc(var(--h4) * var(--type-scale));
    --h2: calc(var(--h3) * var(--type-scale));
    --h1: calc(var(--h2) * var(--type-scale));
    --text-sm: calc(var(--base-size) / var(--type-scale));
}

html{
    box-sizing: border-box;
    scroll-behavior: smooth;
}


*,*:before, *:after{
    box-sizing: inherit;
}

h1,h2,h3,h4,h5{
    font-weight: normal;
    font-family: var(--font-family-header);
}

h1 {font-size: var(--h1);}
  
h2 {font-size: var(--h2);}

h3 {font-size: var(--h3);}

h4 {font-size: var(--h4);}

h5 {font-size: var(--h5);}


p{
    font-size: var(--base-size);
}

small, .text-small {
    font-size: var(--text-sm);
}

body{
    margin: 0;
    font-family: var(--font-family-body);
}

a{
    font-family: var(--font-family-body);
}

@media (max-width: 768px){
    :root{
        --base-size: .8em;
    }
}

@media (max-height: 768px){
    body{
        overflow: auto;
    }
}