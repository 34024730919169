.aboutMe p::after{
    content: " on the right";
}

.socialLinks{
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
}

.socialLinks li{

    display: flex;
}

.socialLinks li img{
    margin: 0;
    padding: 0;
    width: 36px;
    margin-right: 1rem;
}


@media (max-width: 1116px){
    .aboutMe p::after{
        content: " down below";
    }

    .about{
        height: 100%;
        margin: 0;
    }
}

@supports(backdrop-filter: blur(3px)){
    .about{
        backdrop-filter: blur(3px);
    }
}



