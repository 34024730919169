.app{
    display: grid;
    grid-template-columns: 33% 66%;
    grid-template-rows: 33% 66%;
    -ms-grid-columns: 33% 66%;
    -ms-grid-rows: 33% 66%;
    height: 100vh;
    width: 100%;
    overflow: auto;
    padding: 1rem;
    background-image: url(./assets/images/BlobBackground.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.aboutMeWrapper,
.contactMeWrapper{
    display: flex;
    grid-column: 1/2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    border-radius: var(--border-radius-card);
    color: var(--color-neutral-100);
    box-shadow: var(--elevation-1);
}

.aboutMeWrapper{
    flex-flow: column;
    justify-content: space-between;
    /* flex-basis: 100%; */
    box-shadow: var(--elevation-1);
    padding: 1rem;
    /* background-color: var(--color-primary-400); */
    background-color: rgb(69, 89, 140, .95);
    overflow: hidden;
    margin-bottom: 2%;
    margin-right: 1%;
    grid-row: 1/2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
}

.contactMeWrapper{
    overflow: hidden;
    /* flex-basis: 100%; */
    padding: 0 1rem;
    /* background-color: var(--color-neutral-300); */
    background-color: rgba(84, 93, 116, .95);
    grid-row: 2/3;
    margin-bottom: 1%;
    -ms-grid-row: 2;
    -ms-grid-row-span: 3;
}

.projects{
    /* flex-basis: 66%; */
    overflow: auto;
    display: flex;
    flex-flow: row wrap;
    margin-left: 1%;
    grid-row: 1/3;
    grid-column: 2/3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
    height: 100%;
    padding-bottom: 1%;
    padding-left: 1%;
}

.projects article{
    flex-basis: 48%;
    margin-right: 2%;
    height: 49%;
}

@media (max-width: 1116px){
    .app{
        height: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .projects{
        margin: 1rem 0;
    }

    .projects article{
        height: auto;
        overflow: hidden;
        min-height: 400px;
        margin-bottom: 1rem;
    }

    .contactMeWrapper{
        order: 2;
    }
}

@media (max-width: 768px){
    .projects article{
        height: auto;
        flex-basis: 100%;
        margin: 1rem 0;
    }
}

@media (max-height: 768px){
    .app{
        height: 100%;
        overflow: auto;
    }
}